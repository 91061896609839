<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                校企融合，打造金融科技应用人才高地
              </p>
              <div class="fst-italic mb-2">2022年5月29日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >校企合作</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/09/01-校企合作.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    5月29日，上海非凸智能科技有限公司（简称“非凸科技”）与江苏师范大学科文学院经济学院签署校企战略合作协议，双方将在人才培养、科研合作、技术创新等方面展开深度合作，培养适应“数字、科技、法治”时代需求的高技能、高水平应用型经济与金融人才。
                  </p>
                  <p>
                    积极响应国家“深化产教融合，推进校企合作”政策，双方本着优势互补、资源共享、互利互惠、共同发展的原则，将共同建设立足淮海、服务江苏、面向华东、辐射全国的“科技·数字”新科技人才培养基地，为学生提供实践、探索的空间和条件，以期拓宽学生国际视野，成就学生终身发展。
                  </p>
                   <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/09/02-校企合作.jpg"
                      alt="校企合作"
                    />
                  </figure>
                  <p>
                    在签约仪式上，非凸科技业务中台 VP 张行表示，非凸科技紧跟时代步伐，正逐步落地数据中心建设，将在交易合规风控、交易执行、量化交易、数据服务等领域进行深入的探索与尝试，为金融交易能效的提升贡献更多的力量。希望通过此次合作，以实践赋能理论，培养更多金融科技人才。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/09/03-校企合作.jpg"
                      alt="校企合作"
                    />
                  </figure>
                  <p >
                    此次合作，校企双方还就产业联动、学生培养、科研课题、实践创新等多方面展开深入交流。双方一致认为，通过校企之间的“产学研”良性互动，为学生提供了零距离接触行业前沿科技的良好机会，从而实现校企共赢新局面。
                  </p>
                  <p >
                    未来，通过多方优势的叠加，非凸科技将持续为学术界科研成果与交易执行场景应用的结合创造环境，共同推进创新发展。与此同时，我们也期待与更多高校展开合作，充分发挥企业在人才培养中的作用，为量化领域做好长期的专业人才储备。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News01",
};
</script>

<style></style>
